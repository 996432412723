import CryptoJS from 'crypto-js'

/**
 * Función para encriptar datos
 * @param {Object} data Datos a encriptar
 * @returns {String} Retorna datos encriptados
 */
function encriptar(data) {
    return encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString())
}

/**
 * Función para desencriptar datos
 * @param {String} data Datos a desencriptar
 * @returns {Object} Retorna datos desencriptados
 */
function desencriptar(data) {
    var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(data), process.env.REACT_APP_SECRET_KEY);
    return JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
}

export { encriptar, desencriptar }