import React, { useContext, useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Drawer, Toolbar, CssBaseline, List, IconButton, ListItemButton, ListItemText, Icon, Grid, ListItem, Grow, Typography, Accordion, AccordionSummary, AccordionDetails, ListItemIcon } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuPerfil from 'components/MenuPerfil'
import CampoBusqueda from './CampoBusqueda';
import MenuContext from 'context/MenuContext';
import PerfilContext from 'context/PerfilContext';

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: '4rem',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2, 0, 0),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  alignContent: 'center'
}));

export default function Menu() {
  const theme = useTheme();
  const { openMenu, dataMenuPrincipal, handleDrawerOpenMenu, handleDrawerCloseMenu } = useContext(MenuContext)
  const { t } = useTranslation()
  const [dataMenu, setDataMenu] = useState(dataMenuPrincipal)
  const [botonVolver, setBotonVolver] = useState(false)
  const { diseno } = useContext(PerfilContext)
  const logoSrc = diseno && (theme.palette.mode === 'light' ? `${process.env.REACT_APP_URL_FILES}${diseno.rutaArchivoLogoLight}` : `${process.env.REACT_APP_URL_FILES}${diseno.rutaArchivoLogoDark}`);

  useEffect(() => {
    setDataMenu(dataMenuPrincipal)
    setBotonVolver(false)
  }, [dataMenuPrincipal])

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={openMenu}
        sx={{
          backgroundColor: 'secondary.main',
          border: 'none'
        }}
      >
        <Toolbar>
          <Box component="div" sx={{ flexGrow: 1 }} >
            <Box component="img" sx={{ maxWidth: '200px' }} alt="logo" src={logoSrc} />
          </Box>
          <MenuPerfil />
          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpenMenu}
            sx={{ ...(openMenu && { display: 'none' }) }}
          >
            <Icon baseclassname="material-icons" fontSize='large'>menu</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={openMenu} sx={{
        marginRight: openMenu ? drawerWidth : 0,
      }}>
        <DrawerHeader />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: 'secondary.main',
            color: 'primary.light',
            border: 'none',
          },
        }}

        anchor="right"
        elevation={16}
        transitionDuration={1000}
        open={openMenu}
        onClose={handleDrawerCloseMenu}
      >
        <DrawerHeader sx={{ overflow: 'none' }}>
          <IconButton onClick={handleDrawerCloseMenu}>
            {theme.direction === 'rtl' ? <Icon baseclassname="material-icons" fontSize='large' sx={{ color: 'secondary.light' }}>chevron_left</Icon> : <Icon fontSize='large' baseclassname="material-icons" sx={{ color: 'secondary.light' }}>chevron_right</Icon>}
          </IconButton>
          <CampoBusqueda key="search-menu" autoComplete='true' color='secondary.dark' open={true} />
        </DrawerHeader>
        <List component='nav' aria-labelledby='nested-list-subheader' sx={{ overflow: 'overlay' }}>
          <Grid container>
            {dataMenu && <MenuVisual1 t={t} dataMenu={dataMenu} setDataMenu={setDataMenu} dataMenuPrincipal={dataMenuPrincipal} botonVolver={botonVolver} setBotonVolver={setBotonVolver} theme={theme} />}
            {/* {dataMenu && <MenuVisual2 t={t} dataMenu={dataMenu} setDataMenu={setDataMenu} dataMenuPrincipal={dataMenuPrincipal} botonVolver={botonVolver} setBotonVolver={setBotonVolver} theme={theme} />} */}
          </Grid>
        </List>
        <Typography variant="subtitle2" sx={{ mt: 'auto', mx: 'auto', color: 'primary.main', textAlign: 'center' }}>Mi Agenda v1.0.0</Typography>
      </Drawer>
    </>
  );
}

function MenuVisual1({ t, dataMenu, setDataMenu, dataMenuPrincipal, botonVolver, setBotonVolver, theme }) {
  const handleClickMenu = (event, id) => {
    var dataSubMenu = dataMenu.filter(componente => componente.id === id)
    setBotonVolver(true)

    if (dataSubMenu[0].submenu) {
      setDataMenu(dataSubMenu[0].submenu)
    } else {
      setDataMenu(dataSubMenu[0].modulos)
    }
  }

  const handleClickBotonVolver = () => {
    setBotonVolver(false)
    setDataMenu(dataMenuPrincipal)
  }

  const buttonStyles = {
    display: 'grid',
    justifyContent: 'center',
    justifyItems: 'center',
    borderRadius: 5,
    background: `linear-gradient(to top right, ${theme.palette.primary.dark}, ${theme.palette.primary.dark})`,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)',
    minHeight: '90px',
    maxHeight: '90px',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 1)',
    }
  }

  const textButtonStyles = {
    textAlign: 'center',
    color: 'secondary.main',
    '& .MuiTypography-root': {
      fontSize: '0.8rem'
    },
    '& .MuiListItemText-root': {
      p: 0,
      m: 0,
    }
  }

  const iconButtonStyles = {
    color: 'secondary.main',
    p: 0,
    m: 0
  }

  const buttonBackStyles = {
    display: 'grid',
    justifyContent: 'center',
    justifyItems: 'center',
    borderRadius: 5,
    backgroundColor: '#999999',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)',
    minHeight: '90px',
    '&:hover': {
      backgroundColor: '#999999',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 1)',
    }
  }

  return (
    <>
      {botonVolver &&
        <Grow in={true} {...{ timeout: 1000 }}>
          <Grid key="volver" item xs={6}>
            <ListItem>
              <ListItemButton
                onClick={handleClickBotonVolver}
                sx={buttonBackStyles}
              >
                <Icon
                  baseclassname="material-icons"
                  fontSize="large"
                  sx={{ color: 'secondary.main' }}
                >
                  reply_all
                </Icon>
              </ListItemButton>
            </ListItem>
          </Grid>
        </Grow>
      }
      {dataMenu.map((componente) => (
        <Grow in={true} key={(componente.idModulo) ? componente.idModulo : componente.id} {...{ timeout: 1000 }}>
          <Grid key={(componente.idModulo) ? componente.idModulo : componente.id} item xs={6}>
            <ListItem>
              {!componente.idModulo &&
                <ListItemButton
                  onClick={(event) => handleClickMenu(event, componente.id)}
                  sx={buttonStyles}
                >
                  <Icon
                    baseclassname="material-icons"
                    fontSize="large"
                    sx={iconButtonStyles}
                  >
                    {(componente.idModulo) ? componente.iconoModulo : componente.icono}
                  </Icon>
                  <ListItemText
                    sx={textButtonStyles}
                    primary={(componente.idModulo) ? t(`${componente.carpetaModulo}.titulo`) : t(`menu.${componente.traduccion}`)} />
                </ListItemButton>
              }
              {componente.idModulo &&
                <ListItemButton
                  to={componente.urlModulo}
                  component={Link}
                  preventScrollReset={true}
                  sx={buttonStyles}
                >
                  <Icon
                    baseclassname="material-icons"
                    fontSize="large"
                    sx={iconButtonStyles}
                  >
                    {(componente.idModulo) ? componente.iconoModulo : componente.icono}
                  </Icon>
                  <ListItemText
                    sx={textButtonStyles}
                    primary={(componente.idModulo) ? t(`${componente.carpetaModulo}.titulo`) : t(`menu.${componente.traduccion}`)} />
                </ListItemButton>
              }
            </ListItem>
          </Grid>
        </Grow>
      ))
      }
    </>
  )
}

function MenuVisual2({ t, dataMenu, setDataMenu, dataMenuPrincipal, botonVolver, setBotonVolver, theme }) {
  const [expanded, setExpanded] = useState(null);
  const [expanded2, setExpanded2] = useState(null);

  return (
    <Box
      sx={{
        p: 0,
        m: 0,
        width: '100%',
        '&. MuiList-root': { p: 0 },
        '& .MuiPaper-root': { boxShadow: 'none', m: 0, pt: 0, pb: 0 },
        '& .MuiAccordion-root': { borderBottom: 'none', backgroundColor: 'transparent', m: 0 },
        '& .MuiAccordionSummary-root': { minHeight: '40px' },
        '& .Mui-expanded': { minHeight: '40px' },
        '& .MuiAccordionSummary-content': { margin: '0px' },
        '& .MuiAccordionDetails-root': { pl: 1.5, pr: 1.5, pt: 0, pb: 0 }
      }}
    >
      {dataMenu.map((componente, index) => (
        <Accordion
          disableGutters
          square
          key={componente.id}
          expanded={expanded === componente.id}
          onChange={() => setExpanded(expanded === componente.id ? null : componente.id)}
        >
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <ListItemButton sx={{ m: 0, p: 0 }}>
              <ListItemIcon>
                <Icon baseclassname="material-icons">{componente.icono}</Icon>
              </ListItemIcon>
              <ListItemText primary={t(`menu.${componente.traduccion}`)} />
            </ListItemButton>
          </AccordionSummary>
          <AccordionDetails>
            <List key={index} component="nav">
              {componente.modulos && componente.modulos.map((modulo, index) => (
                <ListItemButton key={index} to={modulo.urlModulo} component={Link} preventScrollReset={true} sx={{ m: 0, p: 0.6 }}>
                  <ListItemIcon>
                    <Icon baseclassname="material-icons">{(modulo.idModulo) ? modulo.iconoModulo : modulo.icono}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t(`${modulo.carpetaModulo}.titulo`)} />
                </ListItemButton>
              ))}

              {componente.submenu && componente.submenu.map((submenu, index) => (
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    width: '100%',
                    '&. MuiList-root': { p: 0 },
                    '& .MuiPaper-root': { boxShadow: 'none', m: 0, pt: 0, pb: 0 },
                    '& .MuiAccordion-root': { borderBottom: 'none', backgroundColor: 'transparent', m: 0 },
                    '& .MuiAccordionSummary-root': { minHeight: '40px' },
                    '& .Mui-expanded': { minHeight: '40px' },
                    '& .MuiAccordionSummary-content': { margin: '0px' },
                    '& .MuiAccordionDetails-root': { pl: 1.5, pr: 1.5, pt: 0, pb: 0 }
                  }}
                >
                  <Accordion
                    disableGutters
                    square
                    key={submenu.id}
                    expanded={expanded2 === submenu.id}
                    onChange={() => setExpanded2(expanded2 === submenu.id ? null : submenu.id)}
                    sx={{p: 0}}
                  >
                    <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                      <ListItemButton sx={{ m: 0, p: 0 }}>
                        <ListItemIcon>
                          <Icon baseclassname="material-icons">{submenu.icono}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={t(`menu.${submenu.traduccion}`)} />
                      </ListItemButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List key={index} component="nav">
                        {submenu.modulos && submenu.modulos.map((modulo, index) => (
                          <ListItemButton key={index} to={modulo.urlModulo} component={Link} preventScrollReset={true} sx={{ m: 0, p: 0.6 }}>
                            <ListItemIcon>
                              <Icon baseclassname="material-icons">{(modulo.idModulo) ? modulo.iconoModulo : modulo.icono}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={t(`${modulo.carpetaModulo}.titulo`)} />
                          </ListItemButton>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}