import axios from 'axios'
import createRefresh from 'react-auth-kit/createRefresh';
import { desencriptar } from './encriptado'

export const refresh = createRefresh({
  interval: 10,
  refreshApiCallback: async (param) => {
    try {
      let response = await axios.post(`${process.env.REACT_APP_URL}/login/refresh`, { 'refresh': param.refreshToken }, {
        headers: { 'Authorization': `Bearer ${param.authToken}` }
      })
      response = desencriptar(response.data)
      console.log("Refreshing")
      return {
        isSuccess: true,
        newAuthToken: response[0].token,
        newAuthTokenExpireIn: 15,
        newRefreshTokenExpiresIn: 60
      }
    }
    catch (error) {
      console.error(error)
      return {
        isSuccess: false
      }
    }
  }
})