import React, { useContext } from 'react'
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PerfilContext from 'context/PerfilContext';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Loading } from './Loading';
import { useQuery } from 'react-query';
import axios from 'axios';
import { desencriptar } from 'utilities/encriptado';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 10,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export default function MenuPerfil() {
  const { menuPerfil, idioma, tema, handleMenuPerfil, handleCloseMenuPerfil, handleTema, handleIdioma } = useContext(PerfilContext)
  const { t } = useTranslation()
  const signOut = useSignOut()
  const auth = useAuthUser()
  const navigate = useNavigate()

  const getData = async () => {
    return await axios.get(`${process.env.REACT_APP_URL}/idiomas/listar`, { headers: { 'Authorization': `Bearer ${auth.token}` } }).then(res => desencriptar(res.data))
  }

  const { data, isError, error, isLoading } = useQuery(['idiomas-menu'], getData, { staleTime: Infinity })

  if (isError) {
    return <Typography variant="h5">Error {error.message}</Typography>
  }

  if (isLoading) {
    return <Loading />
  }

  const handleClickLogout = () => {
    handleCloseMenuPerfil()
    signOut()
    navigate("/login")
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenuPerfil}
        color="inherit"
      >
        <Avatar alt={auth.nombreCompletoUsuario} src={(auth.rutaImagenUsuario) ? `${process.env.REACT_APP_URL_FILES}${auth.rutaImagenUsuario}` : ''} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={menuPerfil}
        keepMounted
        open={Boolean(menuPerfil)}
        onClose={handleCloseMenuPerfil}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 25,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ display: 'block' }} divider>
          <Typography variant="subtitle2" align='left' sx={{ width: '100%', fontWeight: 'bold' }}>{auth.primerNombreUsuario + ' ' + auth.primerApellidoUsuario}</Typography>
          <Typography variant="subtitle2" align='left' sx={{ width: '100%' }} gutterBottom>{auth.correoPrincipalUsuario}</Typography>
        </MenuItem>

        <MenuItem divider>
          <Box>
            <FormControlLabel
              label={t('perfil.tema')}
              control={
                <MaterialUISwitch
                  checked={(tema === 'dark') ? true : false}
                  onChange={handleTema}
                />
              }
            />
          </Box>
        </MenuItem>
        <MenuItem divider>
          <Box sx={{ pl: 1 }}>
            <FormControlLabel
              label={t('perfil.idioma')}
              control=
              {
                <FormControl
                  variant="standard"
                  size="small"
                  sx={{
                    '.MuiInputBase-root:before': {
                      borderBottom: 'none'
                    },
                    '.MuiInputBase-root:after': {
                      borderBottom: 'none'
                    }
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={idioma}
                    value={idioma}
                    onChange={handleIdioma}
                    sx={{ borderBottom: 'none' }}
                  >
                    {
                      data.map((idioma, index) => (
                        <MenuItem key={index} value={idioma.codigo} onChange={handleIdioma}>
                          <Avatar alt={idioma.nombre} src={process.env.REACT_APP_URL_FILES + idioma.rutaArchivo} sx={{ maxWidth: '32px', maxHeight: '32px' }} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              }
            />
          </Box>
        </MenuItem>
        <MenuItem onClick={handleClickLogout}>
          <Box sx={{ pl: 1 }}>
            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { pl: 3 } }}
              label={t('login.logout')}
              control={<Avatar sx={{ width: 32, height: 32, bgcolor: 'primary.light' }}><Icon>exit_to_app</Icon></Avatar>}
            />
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}