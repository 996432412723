import { createContext, useState } from "react";
import axios from 'axios'
import { useQuery } from "react-query";
import { Typography } from "@mui/material";
import { Loading } from "components/Loading";
import { desencriptar, encriptar } from "utilities/encriptado";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'

const DataContext = createContext()

export function DataProvider(props) {
  const [search, setSearch] = useState("")
  const auth = useAuthUser()

  const searching = (e) => {
    e.preventDefault()
    setSearch(e.target.value)
  }

  const getData = async () => {
    if (props.id) {
      const dataParam = encriptar({ id: props.id })
      return await axios.get(`${process.env.REACT_APP_URL}/${props.modulo}/${dataParam}`, { headers: { 'Authorization': `Bearer ${auth.token}` } }).then(res => desencriptar(res.data))
    } else {
      return await axios.get(props.url, { headers: { 'Authorization': `Bearer ${auth.token}` } }).then(res => desencriptar(res.data))
    }
  }

  const { data, isError, error, isLoading } = useQuery(props.modulo, getData, { staleTime: Infinity })

  if (isError) {
    return <Typography variant="h5">Error {error.message}</Typography>
  }

  if (isLoading) {
    return <Loading />
  }

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterData = (data) => {
    const filteredData = data.filter((val) => {
      for (let campo of props.filtrosBusqueda) {
        const campoValue = val[campo];
        if (campoValue !== null && removeAccents(campoValue.toString().toLowerCase()).includes(removeAccents(search.toLocaleLowerCase()))) {
          return true;
        }
      }
      return false;
    });

    return filteredData;
  };

  const dataComponente = !search ? data : filterData(data)

  return <DataContext.Provider value={{ dataComponente, search, setSearch, searching }}>{props.children}</DataContext.Provider>
}

export default DataContext