import React, { createContext, useEffect, useState } from 'react'
import 'i18n'
import { useTranslation } from 'react-i18next'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import axios from 'axios'
import { encriptar, desencriptar} from 'utilities/encriptado'

const PerfilContext = createContext()

export function PerfilProvider(props) {
  const auth = useAuthUser()
  const [menuPerfil, setMenuPerfil] = useState(null)
  const [tema, setTema] = useState("light")
  const [idioma, setIdioma] = useState("es")
  const [diseno, setDiseno] = useState(null)
  const { i18n } = useTranslation()

  useEffect(() => {
    setTema((auth) ? auth.tema : 'light')
    setIdioma((auth) ? auth.idioma : 'es')
    i18n.changeLanguage((auth) ? auth.idioma : 'es')
    obtenerConfiguracionDiseno();
  }, [auth, i18n])

  const handleMenuPerfil = (event) => {
    setMenuPerfil(event.currentTarget);
  };

  const handleCloseMenuPerfil = () => {
    setMenuPerfil(null);
  };

  const handleTema = () => {
    if (tema === 'light') {
      setTema('dark')
      auth.tema = 'dark'
      const dataParam = encriptar({ id: auth.idUsuario })
      const dataBody = encriptar({ tema: 'dark' })
      axios.put(`${process.env.REACT_APP_URL}/usuarios/actualizar-tema/${dataParam}`, { dataBody }, { headers: { 'Authorization': `Bearer ${auth.token}` } }).then(res => res.data)

    } else {
      setTema('light')
      auth.tema = 'light'
      const dataParam = encriptar({ id: auth.idUsuario })
      const dataBody = encriptar({ tema: 'light' })
      axios.put(`${process.env.REACT_APP_URL}/usuarios/actualizar-tema/${dataParam}`, { dataBody }, { headers: { 'Authorization': `Bearer ${auth.token}` } }).then(res => res.data)
    }
  };

  const obtenerConfiguracionDiseno = async () => {
    try {
      const dataParam = encriptar({ nombre: 'miagenda' })
      const response = await axios.get(`${process.env.REACT_APP_URL}/sistema/diseno-sistema/${dataParam}`)
      const datos = desencriptar(response.data) 
      setDiseno(datos[0])
    
    } catch (error) {
      console.error('Error al obtener la configuración del diseño:', error)
    }
  };

  const handleIdioma = (event) => {
    setIdioma(event.target.value)
    i18n.changeLanguage(event.target.value)
    auth.idioma = event.target.value
    const dataParam = encriptar({ id: auth.idUsuario })
    const dataBody = encriptar({ idioma: event.target.value })
    axios.put(`${process.env.REACT_APP_URL}/usuarios/actualizar-idioma/${dataParam}`, { dataBody }, { headers: { 'Authorization': `Bearer ${auth.token}` } }).then(res => res.data)
  };

  const datos = { menuPerfil, tema, idioma, diseno, handleMenuPerfil, handleCloseMenuPerfil, handleTema, handleIdioma }

  return <PerfilContext.Provider value={datos}>{props.children}</PerfilContext.Provider>
}

export default PerfilContext