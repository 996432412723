import React, { useContext, useEffect } from 'react'
import axios from 'axios'
import { useMutation } from 'react-query'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Grid, TextField, Button, Box, FormControl, FormHelperText, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import AlertaContext from 'context/AlertaContext'
import { desencriptar, encriptar } from 'utilities/encriptado'
import { useTranslation } from 'react-i18next'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
// import { useTheme } from '@mui/material/styles';
import PerfilContext from 'context/PerfilContext'

export default function Login(props) {
  const { handleOpenAlerta } = useContext(AlertaContext)
  const [showPassword, setShowPassword] = React.useState(false);
  const { diseno } = useContext(PerfilContext)
  const { t } = useTranslation()
  const isAuthenticated = useIsAuthenticated()
  const signIn = useSignIn()
  const navigate = useNavigate()
  //const theme = useTheme();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard")
    }
  }, [isAuthenticated, navigate])

  const mutation = useMutation(async data => {
    const dataBody = encriptar(data)
    return await axios.post(`${process.env.REACT_APP_URL}/login`, { dataBody }).then(res => desencriptar(res.data))
  },
    {
      retry: 3,
      onSuccess: function (response) {
        if (!response.errno && response.length > 0) {
          //Si no hay error
          if (signIn(
            {
              auth: {
                token: response[0].token,
                type: 'Bearer'
              },
              refresh: response[0].refreshToken,
              userState: response[0]
            }
          )) {
            navigate("/dashboard")

          } else {
            handleOpenAlerta('error', t('generico.error'))
          }

        } else {
          //Si hay error
          handleOpenAlerta('error', 'Usuario o contraseña inválido')
        }
      },
      onError: function (error) {
        handleOpenAlerta('error', t('generico.error'))
      }
    })

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  };

  const rutaLogoLogin = diseno?.rutaArchivoLogoLogin ? `${process.env.REACT_APP_URL_FILES}${diseno.rutaArchivoLogoLogin}` : '';
  const backgroundImage = diseno?.rutaArchivoBackground ? `url("${process.env.REACT_APP_URL_FILES}${diseno.rutaArchivoBackground}")` : '';
  const backgroundColor = diseno?.light_primary_main ? `rgba(${hexToRgb(diseno.light_primary_main)}, 0.5)` : '';
  const bienvenida = diseno?.bienvenida || 'Bienvenida';
  const titulo = diseno?.titulo || 'Titulo';
  const descripcion = diseno?.descripcion || 'Descripción.';

  const renderHead = (
    <Box sx={{ width: '100%' }}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Box component="img" sx={{ maxWidth: '80px' }} alt="logo" src={rutaLogoLogin} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('login.login')}</Typography>
      </Grid>
    </Box>
  )

  const renderForm = (
    <Formik
      initialValues={{
        usuario: '',
        password: '',
      }}

      validate={(valores) => {
        let errores = {}

        if (!valores.usuario) {
          errores.usuario = t('login.errores.usuario')
        }

        if (!valores.password) {
          errores.password = t('login.errores.password')
        }

        return errores
      }}

      onSubmit={(valores) => {
        var data = {
          usuario: valores.usuario,
          password: valores.password,
        }

        mutation.mutate(data)
      }}
    >
      {({ errors, touched }) => (
        <Grid container>
          <Grid item xs={0} lg={8} md={8} sx={{ display: { xs: 'none', md: 'block' }, backgroundImage: backgroundImage, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
            <Box sx={{ paddingTop: '10vh', display: 'inline-block' }}>
              <Paper elevation={5} sx={{ p: 2, backgroundColor: backgroundColor }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>{bienvenida}</Typography>
                <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'white' }}>{titulo}</Typography>
                <Typography variant="body1" sx={{ color: 'white', mt: 0.5 }}>{descripcion}</Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} lg={4} md={4}>
            <Paper elevation={5} sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', pl: 5, pr: 5 }}>
              <Form className="formulario" >
                <Grid container spacing={2}>
                  {renderHead}
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      id="usuario"
                      name="usuario"
                      type="text"
                      label={t('login.usuario')}
                      as={TextField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>person</Icon>
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                      error={(touched.usuario && errors.usuario) ? true : false}
                      helperText={<ErrorMessage name="usuario" component={() => (errors.usuario)} />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t('login.password')}</InputLabel>
                      <Field
                        id="password"
                        name="password"
                        label={t('login.password')}
                        type={showPassword ? 'text' : 'password'}
                        as={OutlinedInput}
                        autoComplete="off"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <Icon>key</Icon>
                          </InputAdornment>
                        }
                        error={(touched.password && errors.password) ? true : false}
                      />
                      <FormHelperText error name="password"><ErrorMessage name="password" component={() => (errors.password)} /></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button
                      type='submit'
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={(Object.keys(errors).length > 0) ? true : false}
                      sx={{ width: '50%', textTransform: 'none', borderRadius: 5 }}
                    >
                      {t('login.login')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Link to="/empresas" style={{ textDecoration: 'none', color: 'currentcolor' }}>
                      ¿Olvidó su contraseña?
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Formik>
  );

  return (
    <>
      {renderForm}
    </>
  );
}

