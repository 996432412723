import React, { useState, createContext } from 'react'

const AlertaContext = createContext()

export function AlertaProvider(props) {
  const [stateAlerta, setStateAlerta] = useState({ open: false, vertical: 'bottom', horizontal: 'left', tipo: '', mensaje: '' })

  const handleOpenAlerta = (tipo = 'success', texto = '') => {
    setStateAlerta({ open: true, vertical: stateAlerta.vertical, horizontal: stateAlerta.horizontal, tipo: tipo, mensaje: texto })
  };

  const handleCloseAlerta = () => {
    setStateAlerta({ open: false, vertical: stateAlerta.vertical, horizontal: stateAlerta.horizontal, tipo: stateAlerta.tipo, mensaje: stateAlerta.mensaje })
  };

  const datos = { stateAlerta, handleOpenAlerta, handleCloseAlerta }

  return <AlertaContext.Provider value={datos}>{props.children}</AlertaContext.Provider>
}

export default AlertaContext