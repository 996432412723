import React, { lazy } from 'react'
import Login from 'pages/login/Login'

const Componentes = lazy(() => import('pages/componentes/Componentes'));
const Acciones = lazy(() => import('pages/acciones/Acciones'));
const Modulos = lazy(() => import('pages/modulos/Modulos'));
const Sistema = lazy(() => import('pages/sistema/Sistema'));
const Perfiles = lazy(() => import('pages/perfiles/Perfiles'));
const TiposIdentificacion = lazy(() => import('pages/tipos-identificacion/TiposIdentificacion'));
const Parentescos = lazy(() => import('pages/parentescos/Parentescos'));
const TiposVinculacion = lazy(() => import('pages/tipos-vinculacion/TiposVinculacion'));
const TiposSociedad = lazy(() => import('pages/tipos-sociedad/TiposSociedad'));
const ActividadesEconomicas = lazy(() => import('pages/actividades-economicas/ActividadesEconomicas'));
const Paises = lazy(() => import('pages/paises/Paises'));
const Bancos = lazy(() => import('pages/bancos/Bancos'));
const Cargos = lazy(() => import('pages/cargos/Cargos'));
const Departamentos = lazy(() => import('pages/departamentos/Departamentos'));
const Municipios = lazy(() => import('pages/municipios/Municipios'));
const Barrios = lazy(() => import('pages/barrios/Barrios'));
const Empresas = lazy(() => import('pages/empresas/Empresas'));
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Clientes = lazy(() => import('pages/clientes/Clientes'));
const Entidades = lazy(() => import('pages/entidades/Entidades'));
const Profesionales = lazy(() => import('pages/profesionales/Profesionales'));
const Funcionarios = lazy(() => import('pages/funcionarios/Funcionarios'));
const Proveedores = lazy(() => import('pages/proveedores/Proveedores'));
const Categorias = lazy(() => import('pages/categorias/Categorias'));
const Usuarios = lazy(() => import('pages/usuarios/Usuarios'));
const Citas = lazy(() => import('pages/citas/Citas'));
const Especialidades = lazy(() => import('pages/especialidades/Especialidades'));
const Productos = lazy(() => import('pages/productos/Productos'));
const Monedas = lazy(() => import('pages/monedas/Monedas'));
const MisServicios = lazy(() => import('pages/mis-servicios/MisServicios'));
const Asistencias = lazy(() => import('pages/asistencias/Asistencias'));
const Agenda = lazy(() => import('pages/agenda/Agenda'));
const Idiomas = lazy(() => import('pages/idiomas/Idiomas'));
const Tienda = lazy(() => import('pages/tienda/Tienda'));
const ReporteAsistencias = lazy(() => import('pages/reporte-asistencias/ReporteAsistencias'));
const ReporteVentas = lazy(() => import('pages/reporte-ventas/ReporteVentas'));


const rutasConfig = [
  { path: 'componentes', element: <Componentes /> },
  { path: 'acciones', element: <Acciones /> },
  { path: 'modulos', element: <Modulos /> },
  { path: 'sistema', element: <Sistema /> },
  { path: 'perfiles', element: <Perfiles /> },
  { path: 'tipos-identificacion', element: <TiposIdentificacion /> },
  { path: 'parentescos', element: <Parentescos /> },
  { path: 'tipos-vinculacion', element: <TiposVinculacion /> },
  { path: 'tipos-sociedad', element: <TiposSociedad /> },
  { path: 'actividades-economicas', element: <ActividadesEconomicas /> },
  { path: 'paises', element: <Paises /> },
  { path: 'bancos', element: <Bancos /> },
  { path: 'cargos', element: <Cargos /> },
  { path: 'departamentos', element: <Departamentos /> },
  { path: 'municipios', element: <Municipios /> },
  { path: 'barrios', element: <Barrios /> },
  { path: 'empresas', element: <Empresas /> },
  { path: 'dashboard', element: <Dashboard /> },
  { path: 'login', element: <Login /> },
  { path: 'clientes', element: <Clientes /> },
  { path: 'entidades', element: <Entidades /> },
  { path: 'profesionales', element: <Profesionales /> },
  { path: 'funcionarios', element: <Funcionarios /> },
  { path: 'proveedores', element: <Proveedores /> },
  { path: 'categorias', element: <Categorias /> },
  { path: 'usuarios', element: <Usuarios /> },
  { path: 'citas', element: <Citas /> },
  { path: 'especialidades', element: <Especialidades /> },
  { path: 'productos', element: <Productos /> },
  { path: 'monedas', element: <Monedas /> },
  { path: 'mis-servicios', element: <MisServicios /> },
  { path: 'asistencias', element: <Asistencias /> },
  { path: 'agenda', element: <Agenda /> },
  { path: 'idiomas', element: <Idiomas /> },
  { path: 'tienda', element: <Tienda /> },
  { path: 'reporte-asistencias', element: <ReporteAsistencias /> },
  { path: 'reporte-ventas', element: <ReporteVentas /> },

]

export default rutasConfig;