import React, { useContext, useState } from 'react'
import DataContext from 'context/DataContext';
import { useTranslation } from 'react-i18next';
import { Divider, Icon, IconButton, InputBase, Paper, TextField } from '@mui/material';

export default function CampoBusqueda({ autoComplete = 'off', color = 'primary', open = false }) {
  const { search, searching, setSearch } = useContext(DataContext)
  const { t } = useTranslation()
  const [mostrar, setMostrar] = useState(open)

  const handleClickSetMostrar = () => {
    setMostrar(true)
  }

  const handleClickUnsetMostrar = () => {
    setMostrar(false)
    setSearch("")
  }

  return (
    <Paper
      component="form"
      sx={{
        p: 0,
        borderRadius: 5,
        color: color,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {mostrar &&
        <>
          <InputBase
            autoFocus
            id="search"
            value={search}
            onChange={searching}
            placeholder={t("generico.buscar")}
            type="search"
            size="small"
            autoComplete={autoComplete}
            sx={{
              pl: 1.5,
              pr: 1,
              '& .MuiInputBase-input': { p: 0 }
            }}
            inputProps={{ 'aria-label': t("generico.buscar") }}
            component={TextField}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <Divider sx={{ height: 20 }} orientation="vertical" />
          <IconButton color={color} onClick={handleClickUnsetMostrar} sx={{ p: 1 }}>
            <Icon>power_settings_new</Icon>
          </IconButton>
        </>
      }
      {!mostrar &&
        <IconButton color={color} onClick={handleClickSetMostrar} sx={{ p: 1 }}>
          <Icon>search</Icon>
        </IconButton>
      }
    </Paper>
  )
}