import React, { createContext, useState } from "react";

const DialogoContext = createContext()

export function DialogoProvider(props) {
  const [openDialogo, setOpenDialogo] = useState(false)
  const [formulario, setFormulario] = useState('')
  const [envioFormulario, setEnvioFormulario] = useState(false)
  const [idRegistro, setIdRegistro] = useState('')
  const [registro, setRegistro] = useState('')

  function handleSetOpenDialogo(data) {
    setOpenDialogo(!openDialogo)
    setFormulario(data.accion)
    setIdRegistro(data.id)
    setRegistro(data.registro)
  }

  const data = { openDialogo, formulario, idRegistro, registro, handleSetOpenDialogo, envioFormulario, setEnvioFormulario }

  return <DialogoContext.Provider value={data}>{props.children}</DialogoContext.Provider>
}

export default DialogoContext