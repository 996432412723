import React from 'react'
import AuthProvider from 'react-auth-kit/AuthProvider';
import createStore from 'react-auth-kit/createStore';
import OtrosProvider from 'context/OtrosProvider';
import Rutas from 'Rutas';
import Alerta from 'components/Alerta';
import { PerfilProvider } from 'context/PerfilContext';
import { refresh } from 'utilities/refrescar-token';

export default function App() {
  const store = createStore({
    authName: '_auth',
    authType: 'localstorage',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
    refresh: { refresh }
  });

  return (
    <AuthProvider store={store}>
      <PerfilProvider>
        <OtrosProvider>
          <Rutas />
          <Alerta />
        </OtrosProvider>
      </PerfilProvider>
    </AuthProvider>
  );
}
