import React, { createContext, useContext, useState } from 'react'
import DataContext from './DataContext';

const MenuContext = createContext()

export function MenuProvider(props) {
  const [openMenu, setOpenMenu] = useState(false)
  const { dataComponente, search, setSearch } = useContext(DataContext)

  const handleDrawerOpenMenu = (e) => {
    e.preventDefault()
    setOpenMenu(true);
    setSearch("")
  };

  const handleDrawerCloseMenu = (e) => {
    e.preventDefault()
    setOpenMenu(false);
    setSearch("")
  };

  let idComponentes = []
  var dataMenuPrincipal = []
  const dataMenuSecundario = []
  //Recorro los datos de la base de datos para obtener componentes únicos

  if (!search) {
    dataComponente.forEach((menu) => {
      //Valido si ya existe id en el arreglo idComponentes
      if (!idComponentes.includes(menu.id)) {
        idComponentes.push(menu.id) //Si NO existe agrego el id en el arreglo idComponentes

        let datosComponentesPrincipales = {
          id: menu.id,
          nombre: menu.nombre,
          traduccion: menu.traduccion,
          icono: menu.icono
        } //Lleno un objeto con los datos del componente y modulos

        let datosComponentesSecundarios = {
          id: menu.id,
          nombre: menu.nombre,
          traduccion: menu.traduccion,
          icono: menu.icono,
        } //Lleno un objeto con los datos del componente y modulos

        var modulosPrincipales = []
        modulosPrincipales = dataComponente.filter(modulo => (modulo.id === menu.id && modulo.tipoModulo === '1')) //Filtro los módulos por el id del componente
        if (modulosPrincipales.length) {
          datosComponentesPrincipales["modulos"] = modulosPrincipales //Agrego al arreglo datosComponentes el objeto modulos
          dataMenuPrincipal.push(datosComponentesPrincipales)
        }

        var modulosSecundarios = []
        modulosSecundarios = dataComponente.filter(modulo => (modulo.id === menu.id && modulo.tipoModulo === '2')) //Filtro los módulos por el id del componente
        if (modulosSecundarios.length) {
          datosComponentesSecundarios["modulos"] = modulosSecundarios //Agrego al arreglo datosComponentes el objeto modulos
          dataMenuSecundario.push(datosComponentesSecundarios)
        }
      }
    })

    if (dataMenuSecundario.length) {
      dataMenuPrincipal.push({
        id: 99999,
        nombre: "Configuración",
        traduccion: "configuracion",
        icono: "settings",
        submenu: dataMenuSecundario
      })
    }
  } else {
    dataMenuPrincipal = dataComponente
  }

  const datos = { openMenu, dataMenuPrincipal, handleDrawerOpenMenu, handleDrawerCloseMenu }

  return <MenuContext.Provider value={datos}>{props.children}</MenuContext.Provider>
}

export default MenuContext
