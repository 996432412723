import { CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const Loading = () => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" sx={
      {
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }>
      <CircularProgress />
      <Typography variant='h5' sx={{ padding: '10px' }}>{t('generico.loading')}</Typography>
    </Stack>
  )
}
