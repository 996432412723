import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertaContext from 'context/AlertaContext';
import { Typography } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerta() {
  const { stateAlerta, handleCloseAlerta } = React.useContext(AlertaContext)
  const { open, vertical, horizontal, tipo, mensaje } = stateAlerta;

  return (
    <Stack spacing={2} sx={{ width: '100%'}}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleCloseAlerta}
        autoHideDuration={4000}
        key={vertical + horizontal}
      >
        <Alert severity={tipo} sx={{ width: '100%' }}>
          <Typography>{mensaje}</Typography>
        </Alert>
      </Snackbar>
    </Stack>
  );
}